/* eslint-disable no-continue */

const ConfigurationFactory = require("../ConfigurationFactory");
const ContentSentinelDispatcher = require("content-sentinel");
const EntryPoint = require("../EntryPoint");
const Logger = require("../Logger");
const Utils = require("../Utils");
const ViewerDataInfo = require("../Data/ViewerDataInfo");
const PlaylistItemInfo = require("../Info/PlaylistItemInfo");
const TimelineParser = require("../Parser/TimelineParser");
const PlayerIntegration = require("../Player/PlayerIntegration");
const ContentRenderer = require("../Renderer/ContentRenderer");
const ScheduleController = require("./ScheduleController");
const PresentationController = require("./PresentationController");
const PersistentNotificationPanel = require("../UI/PersistentNotificationPanel");
const PersistentNotificationType = require("../Info/PersistentNotificationType");
const TimerController = require("./TimerController");
const PlayerMessaging = require("../Player/PlayerMessaging");
const ViewerZoomController = require("../UI/ViewerZoomController");
const ScreenSharingController = require("./ScreenSharingController");
const ScreenSharingUIController = require("../UI/ScreenSharingUIController");

const ContentController = function (onContentReady, onContentDone) {
  const factory = {};

  let content;
  let status;

  // Array of Schedule objects from the content bundle
  // It is assumed these items can be used for Timeline checks
  // These items do not have playUntilDone or duration values
  // items & scheduleControllers array indexes are mapped
  const items = [];
  const scheduleControllers = [];
  let currentItem = -1, nextItem = -1, lastItem = -1;
  let doneReceived = false;

  let timerController;
  let resizeTimer;
  let itemTime = -1;
  let scheduleTime = -1;

  let isPlaying = false;

  let notificationType = null;

  const _onContentReady = () => {
    if (onContentReady) {
      onContentReady();
    }
  };

  factory.checkPriorityContent = () => {
    let hasPriorityContent = false;
    if (items && items.length) {
      for (let i = 0; i < items.length; i++) {
        if (TimelineParser.canPlay(items[i]) && _isOverrideReplace(items[i])) {
          hasPriorityContent = true;
        }
      }
    }

    return hasPriorityContent;
  };

  const _notifyOfRegularContent = () => {
    ScreenSharingUIController.setPriorityContentPlaying(false);

    if (Utils.wakeupSignalHasBeenReceived()) {
      Logger.logDebug('notifyOfRegularContent called');

      PlayerIntegration.notifyOfRegularContent();
    }
  }

  const _setCurrentItem = (index) => {
    currentItem = index;

    let scheduleId = null;

    if (index === -1) {
      PlayerMessaging.sendCurrentScheduleItem(null);
    } else {
      scheduleId = content[index].id;
    }

    Logger.setEndpointLoggerContentFields({
      scheduleId
    });

    if (index === -1 || !_isOverrideReplace(items[index])) {
      _notifyOfRegularContent();
    } else {
      ScreenSharingUIController.setPriorityContentPlaying(true);
    }
  };

  const _sendCompanyIdToPlayer = () => {
    const companyId = ConfigurationFactory.getCompanyId();

    if (companyId) {
      PlayerIntegration.setCompanyId(companyId);
    }
  };

  factory.init = () => {
    content = ViewerDataInfo.getSchedules();

    if (content) {
      _sendCompanyIdToPlayer();

      if (ConfigurationFactory.useContentSentinel()) {
        const scheduleId = content[0] ? content[0].id : "";

        // Content sentinel doesn't require overriding shedule ids; so this top-level notification is enough - https://docs.google.com/document/d/12o_JXpovhSA_xZ2V3ziQbmqVA-1bUnRrdI-dJf2BTK8/edit
        ContentSentinelDispatcher.clearReceivers();
        ContentSentinelDispatcher.sendControllerMessage({msg: "set-schedule-id", id: scheduleId}, window);
      }
      _bindContent();
    }
  };

  let _bindContent = function () {
    let presentationCount = 0;
    content.containerName = ContentRenderer.render();

    PresentationController.clearInstances();

    let itemCount = 0;
    for (let i = 0; i < content.length; i++) {
      const item = content[i];
      let scheduleController;

      if (_isScreenSharing(item)) {
        scheduleController = new ScreenSharingController(item,
          content.containerName + "_sce" + itemCount,
          content.containerName,
          _onScheduleReady,
          _onScheduleDone);
      } else {
        scheduleController = new ScheduleController(item,
          content.containerName + "_sce" + itemCount,
          content.containerName,
          _onScheduleReady,
          _onScheduleDone);

        if (item.isNotScheduled) {
          _setNoContentNotification(PersistentNotificationType.NO_SCHEDULE);
        } else if (item.isMissingLicense) {
          _setNoContentNotification(PersistentNotificationType.NO_DISPLAY_LICENSE);
        }
      }

      scheduleControllers.push(scheduleController);

      items.push(item);
      itemCount++;
    }

    for (let i = 0; i < scheduleControllers.length; i++) {
      presentationCount += scheduleControllers[i].init();
    }

    if (presentationCount === 0 && !notificationType) {
      _setNoContentNotification(PersistentNotificationType.NO_PRESENTATION_SCHEDULED);
    }

    if (notificationType) {
      PersistentNotificationPanel.setJoinCode();

      _showNoContentNotification(true);
    } else {
      PersistentNotificationPanel.hide();
    }

    if (content.length === 0) {
      _onContentReady();
    // } else if (scheduleControllers.length === 1 && EntryPoint.isShowingProgressBar() && ConfigurationFactory.isDisplay() && !ConfigurationFactory.isEmbed()) {
    //   factory.play();
    }
  };

  let _onScheduleReady = (force) => {
    if (status !== PlaylistItemInfo.READY_STATUS) {
      // [AD] - All ready signifies all items are ready and if none canPlay(), ready command will execute
      // and the screen will go black
      let allReady = true;
      for (let i = 0; i < scheduleControllers.length; i++) {
        if (scheduleControllers[i].isReady()) {
          // [AD] - Added extra check for item.canPlay() or else the ready command is called and
          // no items would be ready
          if (_checkCanPlay(items[i])) {
            status = PlaylistItemInfo.READY_STATUS;
            break;
          }
        }
        else {
          allReady = false;
        }
      }

      if (status === PlaylistItemInfo.READY_STATUS || allReady) {
        status = PlaylistItemInfo.READY_STATUS;
        _onContentReady();
      }
    }

    // if lastItem == -1 then we haven't played through any
    if ((isPlaying && lastItem === -1 && currentItem === -1) || force) {
      _playNextItem(true);
    }
  };

  let _onScheduleDone = () => {
    if (doneReceived) {
      Logger.logDebug(`Schedule ${ConfigurationFactory.isEmbed() ? "(Embedded) " : ""}- Done received multiple times!`);

      scheduleControllers[currentItem].stop();

      // Will trigger next item playback
      _setCurrentItem(-1);
    } else {
      doneReceived = true;
    }

    if (items.length !== 1) {
      // Next item should play - reset doneReceived
      doneReceived = false;
    }

    _playNextItem(true);

    // Reset doneReceived once play has executed
    // to prevent recursion loop
    doneReceived = false;
  };

  const _timerExecute = () => {
    if (itemTime != -1) {
      itemTime--;
    }

    // calculate the next item time
    if (itemTime == 0) {
      _playNextItem(true);
    }

    if (scheduleTime != -1) {
      scheduleTime--;
    }

    if (scheduleTime == 0) {
      _verifySchedule(true);
    }
  };

  const _setNextItemCheck = (seconds) => {
    itemTime = seconds;
  };

  const _setNextContentCheck = (seconds) => {
    scheduleTime = seconds;
  };

  let _verifySchedule = (executeDoneCommand) => {
    if (!isPlaying) {
      isPlaying = true;
      _playNextItem(executeDoneCommand);
    }
    else if (currentItem !== -1 && !_checkCanPlay(items[currentItem])) {
      _playNextItem(executeDoneCommand);
    }

    _setNextContentCheck(60 - new Date().getSeconds());

    // load if needed
    for (let i = 0; i < items.length; i++) {
      _loadSchedule(i);
    }
  };

  let _playNextItem = (executeDoneCommand) => {
    // Reset any previous timers
    _setNextItemCheck(-1);

    // only send Done if an item actually played
    if (onContentDone && nextItem === items.length - 1) {
      nextItem = -1;
      isPlaying = false;

      if (executeDoneCommand && lastItem !== -1) {
        Logger.logDebug(`Schedule ${ConfigurationFactory.isEmbed() ? "(Embedded) " : ""}- executeDoneCommand!`);

        onContentDone();
      } else if (lastItem === -1 && currentItem !== -1) {
        scheduleControllers[currentItem].stop();

        // we haven't played through any items yet
        _setCurrentItem(-1);
      }

      return;
    }

    // signifies Done was sent right after Play
    if (isPlaying && lastItem !== currentItem && currentItem !== -1 && scheduleControllers[currentItem].isReady()) {
      Logger.logDebug(`Schedule ${ConfigurationFactory.isEmbed() ? "(Embedded) " : ""}- Done after play Stop!`);

      scheduleControllers[currentItem].stop();

      // we haven't played through any items yet
      if (lastItem === -1) {
        _setCurrentItem(-1);
      } else if (nextItem === items.length - 1) {
        // Stop the last item just in case before clearing reference
        scheduleControllers[lastItem].stop();

        lastItem = -1;
      }
    }

    _setNextItem();

    if (nextItem === -1) {
      Logger.logDebug(`Schedule ${ConfigurationFactory.isEmbed() ? "(Embedded) " : ""}- end of playback stop!`);

      // This will stop playback; stop the last item just in case
      if (lastItem !== -1) {
        scheduleControllers[lastItem].stop();

        lastItem = -1;
      }

      _setNextItemCheck(60 - new Date().getSeconds());

      _notifyOfRegularContent();
      _showBlackScreen(true);
    } else if (!_checkCanPlay(items[nextItem])) {
      if (nextItem === currentItem) {
        _setCurrentItem(-1);
      }

      if (scheduleControllers.length !== 1) {
        _unloadSchedule(nextItem);
      }

      _playNextItem(executeDoneCommand);
    } else if (!scheduleControllers[nextItem].isReady()) {
      // if the item is not ready, skip it
      scheduleControllers[currentItem] && scheduleControllers[currentItem].pause();
      _playNextItem(executeDoneCommand);
    } else {
      _showBlackScreen(false);
      _setCurrentItem(nextItem);

      Logger.logDebug(`Content ${ConfigurationFactory.isEmbed() ? "(Embedded) " : ""}- Counters before - last:${lastItem} current:${currentItem} next:${nextItem}`);

      scheduleControllers[currentItem].play();

      if (lastItem !== -1 && currentItem !== lastItem && scheduleControllers[lastItem] !== scheduleControllers[currentItem]) {
        // AD: Fixes issues with Gadgets not having the stop command implemented
        scheduleControllers[lastItem].pause();
      }

      Logger.logDebug(`Content ${ConfigurationFactory.isEmbed() ? "(Embedded) " : ""}- Counters after - last:${lastItem} current:${currentItem} next:${nextItem}`);

      // [AD] Only stop propagation when an item is switched
      if (currentItem !== -1 && lastItem !== -1 && currentItem !== lastItem) {
        Logger.logDebug("Stop propagation");

        // because the play() command sets all the Placeholders moving, no need to call any more commands
        // in the timer controller
        timerController.stopPropagation();
      }

      lastItem = currentItem;
    }
  };

  let _setNextItem = () => {
    if (nextItem < scheduleControllers.length - 1) {
      nextItem++;
    } else if (currentItem !== -1) {
      nextItem = 0;
    } else {
      nextItem = -1;
    }
  };

  factory.unload = () => {
    factory.stop();
    _setNextContentCheck(-1);

    onContentReady = null;

    TimerController.destroyOldInstance();

    for (let i = 0; i < scheduleControllers.length; i++) {
      scheduleControllers[i].unload();
    }

    // Workaround for HTML5 Video Gadget
    setTimeout(() => {
      Utils.destroyContainer(content.containerName);
    }, 10 * 1000);

    window.removeEventListener("resize", _resizeTimerHandler);
  //		ViewerHtmlUtils.destroyContainer('sc' + thisCount);
  };

  let _isOverrideReplace = (item) => {
    if (!item) {
      return false;
    }

    return item.scheduleType === PlaylistItemInfo.OVERRIDE_REPLACE;
  };

  let _isScreenSharing = (item) => {
    if (!item) {
      return false;
    }

    return item.scheduleType === PlaylistItemInfo.SCREEN_SHARING;
  };

  const _isScreenSharingActive = (item) => {
    if (!item) {
      return false;
    }

    return _isScreenSharing(item) && item.isSharing;
  };

  let _checkCanPlay = (item, date) => {
    let itemLastStarted;
    if (!TimelineParser.canPlay(item, date)) {
      return false;
    }

    if (_isScreenSharing(item) && !_isScreenSharingActive(item)) {
      return false;
    }

    if (_isOverrideReplace(item)) {
      itemLastStarted = TimelineParser.lastStarted(item, date);
    } else if (_isScreenSharingActive(item)) {
      itemLastStarted = item.lastStarted;
    }

    for (let i = 0; i < items.length; i++) {
      const thisItem = items[i];
      // Skip checking the same item, or items that can't play
      if (thisItem === item || !TimelineParser.canPlay(thisItem, date)) {
        continue;
      }

      // Check if override item actually played, or if screen sharing is active
      if ((_isOverrideReplace(thisItem) && !thisItem.didNotPlay) || _isScreenSharingActive(thisItem)) {
        // Regular items should not play
        if (!_isOverrideReplace(item) && !_isScreenSharingActive(item)) {
          return false;
        } else {
          let lastStarted;

          if (_isOverrideReplace(thisItem)) {
            lastStarted = TimelineParser.lastStarted(thisItem, date);
          } else if (_isScreenSharingActive(thisItem)) {
            lastStarted = thisItem.lastStarted;
          }

          // Check for who started first.
          if (lastStarted > itemLastStarted) {
            if (_isScreenSharingActive(item)) {
              scheduleControllers[items.indexOf(item)].sendDisplayInterrupted();
            }

            return false;
          }
        }
      }
    }

    return true;
  }

  let _unloadSchedule = (item) => {
    if (item !== -1 && scheduleControllers[item].getStatus() !== PlaylistItemInfo.UNLOADED_STATUS
        && !TimelineParser.canPlay(items[item], new Date(new Date().getTime() + PlaylistItemInfo.UNLOAD_TIME))) {
      scheduleControllers[item].unloadSchedule();
    }
  };

  let _loadSchedule = (item) => {
    if (item !== -1 && scheduleControllers[item].getStatus() === PlaylistItemInfo.UNLOADED_STATUS
        && TimelineParser.canPlay(items[item], new Date(new Date().getTime() + PlaylistItemInfo.UNLOAD_TIME))) {
      scheduleControllers[item].loadSchedule();
    }
  };

  factory.play = () => {
    if (!isPlaying) {
      timerController.startTimer();

      if (TimelineParser.applies()) {
        _verifySchedule(false);
      }
      else {
        isPlaying = true;
        _playNextItem(false);
      }
    }

    // returning false calls embedDone
    return !onContentDone || scheduleControllers.length === 0 || isPlaying;
  };

  factory.stop = () => {
    Logger.logDebug(`Content ${ConfigurationFactory.isEmbed() ? "(Embedded) " : ""}- Stop!`);

    // if (isPlaying) {
    isPlaying = false;

    _setNextItemCheck(-1);
    _setNextContentCheck(-1);

    for (let i = 0; i < scheduleControllers.length; i++) {
      scheduleControllers[i].stop();
    }

      // [AD] Prevent done next time the schedule is played
    if (nextItem === scheduleControllers.length - 1) {
      nextItem = -1;
    }
    // }
  };

  factory.pause = () => {
    Logger.logDebug(`Content ${ConfigurationFactory.isEmbed() ? "(Embedded) " : ""}- Pause!`);

    // if (isPlaying) {
    isPlaying = false;

    _setNextItemCheck(-1);
    _setNextContentCheck(-1);

    for (let i = 0; i < scheduleControllers.length; i++) {
      scheduleControllers[i].pause();
    }

      // [AD] Prevent done next time the schedule is played
    if (nextItem === scheduleControllers.length - 1) {
      nextItem = -1;
    }
    // }
  };

  function _showBlackScreen(show) {
    if (ConfigurationFactory.isSharedschedule() || notificationType) {
      _showNoContentNotification(show);
    }

    EntryPoint.showBlackScreen(show);
  }

  function _setNoContentNotification(type) {
    notificationType = type;
  }

  function _showNoContentNotification(show) {
    if (show) {
      if (ConfigurationFactory.isSharedschedule()) {
        PersistentNotificationPanel.show(PersistentNotificationType.NO_TEMPLATE_SCHEDULED);
      } else if (notificationType) {
        PersistentNotificationPanel.show(notificationType);
      }
    } else {
      PersistentNotificationPanel.hide();
    }
  }

  let oldWindowSize = "";

  const _onWindowResize = () => {
    let newWindowSize = `${window.innerWidth}x${window.innerHeight}`;

    if (!ConfigurationFactory.isPreview()) {
      ViewerZoomController.zoomFit();

      if (oldWindowSize !== newWindowSize) {
        window.setRiseOrientation();
        oldWindowSize = newWindowSize;
      }
    } else {
      const height = window.innerHeight;
      const width = window.innerWidth;

      Logger.logExternalMessage("window resize", { width, height });
      Logger.viewerDebug("window resize", JSON.stringify({ width, height }));

      for (let i = 0; i < scheduleControllers.length; i++) {
        scheduleControllers[i].resize();
      }
    }
  };

  const _resizeTimerHandler = () => {
    clearTimeout(resizeTimer);

    resizeTimer = setTimeout(_onWindowResize, 250);
  };

  (function () {
    timerController = TimerController.createInstance();
    timerController.addTimerCommand(_timerExecute);

    window.addEventListener("resize", _resizeTimerHandler);
  }());

  // for testing only
  factory._checkCanPlay = _checkCanPlay;
  factory._setItems = (newItems) => {
    Array.prototype.push.apply(items, newItems);
  };

  return factory;
};

module.exports = ContentController;
