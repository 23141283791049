const Player = require("../Player/PlayerIntegration")

function mouseMovementHandler(documentBody) {
  let previousX = 0;
  let previousY = 0;

  documentBody.on("mousemove", (event) => {
    const currentX = event.clientX;
    const currentY = event.clientY;

    const movementDistance = Math.sqrt(
      Math.pow(currentX - previousX, 2) +
      Math.pow(currentY - previousY, 2)
    ).toFixed(2);

    previousX = currentX;
    previousY = currentY;

    if (Number(movementDistance) >= 2.0) {
      Player.exitPlayer();
    }
  });
}

function keyPressHandler(documentBody) {
  documentBody.on("keydown", () => {
    Player.exitPlayer()
  });
}

function mouseButtonHandler(documentBody) {
  documentBody.on("mousedown", () => {
    Player.exitPlayer()
  });
}

function touchHandler(documentBody) {
  documentBody.on("touchstart", () => {
    Player.exitPlayer()
  });
}

module.exports = {
  init: function() {
    const documentBody = window.$("body");
    mouseMovementHandler(documentBody);
    keyPressHandler(documentBody);
    mouseButtonHandler(documentBody);
    touchHandler(documentBody);
  }
};
