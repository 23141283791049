const queryString = require("query-string");

const Logger = require("./Logger");
const Utils = require("./Utils");
const Platform = require("./Platform");

const service = {};

service.DISPLAY = "display";
service.SCHEDULE = "schedule";
service.SHAREDSCHEDULE = "sharedschedule";
service.WEBPLAYER = "webplayer";
service.PRESENTATION = "presentation";
service.EXTENSION = "extension";
service.SHARED_SCHEDULE_EMBED = "embed";
service.CHROME_OS_PWA_PLAYER_CONFIGURATION = "chrome-os-pwa-player-configuration";
service.WEBPLAYER_DISPLAY_ID_KEY = "webplayer-display-id";
service.WEBPLAYER_DISPLAY_ID_AS_CANCELLED = "webplayer-display-id-as-cancelled";
service.WEBPLAYER_ACTIVATION_CODE = "webplayer-activation-code";
service.WEBPLAYER_ACTIVATION_CODE_TIMESTAMP = "webplayer-activation-code-timestamp";

service.DEMO_ID = "demo";

const TYPE_PARAM = "type=";
const ID_PARAM = "id=";
const CLAIM_ID_PARAM = "claimId=";
const PARENT_ID_PARAM = "parentId=";
const DISPLAY_ID_PARAM = "displayId=";
const SHOW_UI_PARAM = "showUi=";
const SYS_INFO_PARAM = "sysInfo=";
const ENV_PARAM = "env=";
const SCHEDULE_ID_PARAM = "scheduleId=";
const RUNNING_INTERVAL = 21600000; // 6 hours
const RUNNING_MESSAGE = "running";
const GIADA_RECOMMENDED_PRODUCT_NAMES = [ "jhs558", "DN76V1"];
const AVOCOR_R_SERIES_PRODUCT_NAMES = [ "smith", "AVR-4310", "AVR-5510" ];
const AVOCOR_K_SERIES_PRODUCT_NAMES = [ "AVK-5510", "AVK-6510", "AVK-7510", "AVK-8510", "AVK-9810" ];

let type;
let id;
let claimId;
let parentId;
let showUi = true;
let env;

let isEmbed = false;
let sysInfo;
let scaleContentToFitScreen = false;

service.updateParameters = () => {
  // example: queryString = '?type=display&id=1d3b23d3-31f6-4ad8-b8b1-c180231b9919';
  // example: queryString = '?type=display&id=1d3b23d3-31f6-4ad8-b8b1-c180231b9919&CompanyAuthKey=';
  // example: queryString = '?type=preview'; **** DEPRECATED
  // example: queryString = '?type=presentation&id=1d3b23d3-31f6-4ad8-b8b1-c180231b9919&showui=false';
  type = Utils.getFromQueryString(TYPE_PARAM);
  if (type == null) {
    type = service.DISPLAY;
  }

  id = Utils.getFromQueryString(ID_PARAM);
  if (!id) {
    id = service.DEMO_ID;
  }

  parentId = Utils.getFromQueryString(PARENT_ID_PARAM);
  if (parentId != null) {
    isEmbed = true;
  } else {
    isEmbed = false;
  }

  window.setIsRiseEmbed(isEmbed);

  if (id && id !== service.DEMO_ID && !isEmbed && service.isWebPlayer()) {
    try {
      service.setDisplayId(id);
    } catch(e) {console.log(e)}
  }

  claimId = Utils.getFromQueryString(CLAIM_ID_PARAM);
  showUi = !Utils.getFromQueryString(SHOW_UI_PARAM) || Utils.getFromQueryString(SHOW_UI_PARAM).toLowerCase() !== "false";
  sysInfo = Utils.getFromQueryString(SYS_INFO_PARAM);
  env = Utils.getFromQueryString(ENV_PARAM);

  window.setIsIgnoredRotationDevice(service.isIgnoredRotationDevice());
  window.setIsForcedRotationDevice(service.isForcedRotationDevice());
  window.setIsSharedSchedule(service.isSharedschedule());
};

service.useContentSentinel = () => {
  if (isEmbed) {
    return false;
  }

  return service.isSharedschedule() || service.isExtension() || service.isWebPlayer();
};

service.endpointType = () => {
  if (service.isExtension()) { return "Extension"; }
  if (service.isDisplay()) { return "Display"; }
  if (service.isWebPlayer()) { return "Display"; }
  if (service.isInApp()) { return "InApp"; }
  if (service.isEmbedSharedSchedule()) { return "Embed"; }
  if (service.isSharedschedule()) { return "URL"; }

  return "Unknown";
};

service.displayLogging = () => {
  if (!service.isDisplay()) { return; }

  Logger.logExternalMessage("startup", JSON.stringify({
    isEmbed,
    href: window.location.href,
    postToPlayer: typeof window.postToPlayer,
    receiveFromPlayer: typeof window.receiveFromPlayer,
    disableViewerContentFetch: window.disableViewerContentFetch,
    useWindowMessagingForLocalMessaging: window.useWindowMessagingForLocalMessaging
  }));
  Logger.viewerDebug("startup", JSON.stringify({
    isEmbed,
    href: window.location.href,
    postToPlayer: typeof window.postToPlayer,
    receiveFromPlayer: typeof window.receiveFromPlayer,
    disableViewerContentFetch: window.disableViewerContentFetch,
    useWindowMessagingForLocalMessaging: window.useWindowMessagingForLocalMessaging
  }));

  // loads static methods to be called through JSNI
  Utils.reportViewerConfigToPlayer();

  // log imlive event every 6 hours so a display that does not restart everyday is counted as active on the logs
  Logger.logExternalMessage(RUNNING_MESSAGE);
  setInterval(() => {
    Logger.logExternalMessage(RUNNING_MESSAGE);
  }, RUNNING_INTERVAL);
};

service.getType = () => { return type; };

service.isValidType = function () {
  return type !== null &&
    (type === service.DISPLAY || type === service.PRESENTATION || type === service.SCHEDULE || type === service.SHAREDSCHEDULE || type === service.WEBPLAYER);
};

service.isDisplay = function () {
  return service.DISPLAY === type;
};

service.isIdInURL = function () {
  return !!Utils.getFromQueryString(ID_PARAM);
};

service.isInApp = function () {
  return service.getEnv(true).indexOf("apps_") === 0;
};

service.isExtension = function () {
  return service.getEnv() === service.EXTENSION;
};

service.isWebPlayer = function () {
  return service.WEBPLAYER === type;
};

service.isSharedschedule = function () {
  return service.SHAREDSCHEDULE === type;
};

service.isPresentation = function () {
  return service.PRESENTATION === type;
};

service.isPreview = function () {
  return (service.PRESENTATION === type || service.SCHEDULE === type) && !isEmbed;
};

service.isEmbed = function () {
  return isEmbed;
};

service.isEmbedSharedSchedule = function () {
  return service.isSharedschedule() && service.getEnv() === service.SHARED_SCHEDULE_EMBED;
};

service.getIDParamName = function () {
  return ID_PARAM.replace("=", "");
};

service.getDisplayId = function () {
  if (service.isDisplay() || service.isWebPlayer()) {
    if (isEmbed) {
      return Utils.getFromQueryString(DISPLAY_ID_PARAM);
    }
    else {
      return service.getId();
    }
  }
  return "";
};

service.setDisplayId = function (displayId) {
  localStorage.setItem(service.WEBPLAYER_DISPLAY_ID_KEY, displayId);
};

service.getDisplayIdAsCancelled = function () {
  if (service.isDisplay() || service.isWebPlayer()) {
    return localStorage.getItem(service.WEBPLAYER_DISPLAY_ID_AS_CANCELLED);
  }
  return null;
};

service.getScheduleId = function () {
  if (isEmbed) {
    return Utils.getFromQueryString(SCHEDULE_ID_PARAM);
  }
  if (service.isSharedschedule()) {
    return id || "";
  }

  return "";
};

service.getId = () => {
  return service.isWebPlayer() && (id === service.DEMO_ID || !id) ?
    localStorage.getItem(service.WEBPLAYER_DISPLAY_ID_KEY) || "" :
    id || "";
};

service.getCompanyId = () => { return RiseVision.Viewer.Data.ViewerDataInfo.getItemCompanyId(); }

service.getParentId = () => { return parentId; };

service.getClaimId = () => { return claimId; };

service.getSysInfo = function () {
  return sysInfo ? sysInfo : "";
};

service.getSysInfoObject = function () {
  if (!sysInfo) {
    return null;
  }

  try {
    return queryString.parse(decodeURIComponent(sysInfo));
  } catch(error) {
    Logger.log(`Could not parse sysinfo: ${sysInfo} - ${error.stack || error.message}`);

    return null;
  }
}

service.getProductName = () => {
  const sysInfo = service.getSysInfoObject();

  return ( sysInfo && sysInfo.product_name ) || "";
};

service.getShowUi = () => { return showUi; };

service.getEnv = (includeDefault) => {
  return includeDefault ? (env || "url") : env;
};

service.isAndroidPlayer = () => {
  return service.getEnv() === "android";
}

service.isFireOSPlayer = () => {
  if (!service.isAndroidPlayer()) {
    return false;
  }

  const info = service.getSysInfoObject();

  return info && info.build_type === "amazon-store";
}

service.isGiadaRecommendedDevice = () => {
  return service.isAndroidPlayer() && GIADA_RECOMMENDED_PRODUCT_NAMES.includes(service.getProductName());
}

service.isAvocorRSeries = () => {
  return service.isAndroidPlayer() &&  AVOCOR_R_SERIES_PRODUCT_NAMES.includes( service.getProductName() );
}

service.isAvocorKSeries = () => {
  return service.isAndroidPlayer() && AVOCOR_K_SERIES_PRODUCT_NAMES.includes(service.getProductName());
};

service.isChromeOSAppPlayer = () => {
  return service.getEnv() === "chromeosapp";
}

service.isIgnoredRotationDevice = () => {
  return service.isGiadaRecommendedDevice();
}

service.isForcedRotationDevice = () => {
  return service.isAndroidPlayer() && !service.isGiadaRecommendedDevice();
}

service.isElectronPlayer = () => {
  // "windows" can be removed once all "Windows Electron Players" are upgraded to
  // the renamed "Electron Player"
  return service.getEnv() === "windows" || service.getEnv() === "electron";
}

service.isWebPlayerBasedRisePlayer = () => {
  return service.isAndroidPlayer() ||
         service.isChromeOSAppPlayer() ||
         service.isElectronPlayer();
}

service.isAirtame = function() {
  return /Airtame[/]/.test(Platform.getUserAgent())
}

service.viewerSupportsDisplayControl = () => {
  return service.isElectronPlayer() || service.isGiadaRecommendedDevice() || service.isAvocorRSeries() || service.isAvocorKSeries();
}

service.needsWebPlayerDisplayId = function() {
  if (!service.isWebPlayer()) {return false;}
  if (service.isEmbed()) {return false;}
  if (service.getId() && !service.getDisplayIdAsCancelled()) {return false;}

  return true;
};

service.checkDistribution = function (distribution) {
  if (!(service.isDisplay() || service.isWebPlayer()) || !distribution) {
    return true;
  }

  const displayId = service.getDisplayId();
  for (let i = 0; i < distribution.length; i++) {
    if (distribution[i] === displayId) {
      return true;
    }
  }
  return false;
};

service.getWindowWidth = function () {
  if (service.isScaleContentToFitScreen() || service.isSharedschedule()) {
    return 1920;
  }

  if (service.isAndroidPlayer()) {
    return Math.round( screen.width * window.devicePixelRatio );
  }

  return window.innerWidth;
};

service.getWindowHeight = function() {
  if (service.isScaleContentToFitScreen() || service.isSharedschedule()) {
    return 1080;
  }

  if (service.isAndroidPlayer()) {
    return Math.round( screen.height * window.devicePixelRatio );
  }

  return window.innerHeight;
}

service.isChrome = function() {
  return /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
};

service.getActivationCode = function() {
  if (service.isWebPlayer()) {
    return localStorage.getItem(service.WEBPLAYER_ACTIVATION_CODE);
  } else {
    return null;
  }
}

service.getActivationCodeTimestamp = function() {
  const timestamp = localStorage.getItem(service.WEBPLAYER_ACTIVATION_CODE_TIMESTAMP);

  return timestamp ? Number(timestamp) : 0;
}

service.removeActivationCode = function() {
  localStorage.removeItem(service.WEBPLAYER_ACTIVATION_CODE);
  localStorage.removeItem(service.WEBPLAYER_ACTIVATION_CODE_TIMESTAMP);
}

service.getDisplayName = () => { return RiseVision.Viewer.Data.ViewerDataInfo.getDisplayName(); }

service.isChromeOsPwaPlayerExtensionAvailable = function() {
  const configuration = window.sessionStorage.getItem(service.CHROME_OS_PWA_PLAYER_CONFIGURATION);

  return Boolean( configuration );
}

service.getChromeOSPwaConfiguration = function() {
  let configuration = {};

  const configurationString = window.sessionStorage.getItem( service.CHROME_OS_PWA_PLAYER_CONFIGURATION );
  Logger.log(`Player Data - reading configuration: '${ configurationString }'`);

  try {
    configuration = JSON.parse( configurationString );
  } catch (e) {
    Logger.log("Player Data - invalid PWA player config");
    return {};
  }

  return configuration;
}

service.isScaleContentToFitScreen = () => {
  return scaleContentToFitScreen;
}

service.setScaleContentToFitScreen = (value) => {
  scaleContentToFitScreen = value;
}

module.exports = service;
