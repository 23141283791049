// Copyright © 2010 - May 2014 Rise Vision Incorporated.
// Use of this software is governed by the GPLv3 license
// (reproduced in the LICENSE file).

(function () {
  try {
    window.onload = function () {
      // Initialize page style...
      document.body.style.MozUserSelect = "none";
      document.body.style.KhtmlUserSelect = "none";
      document.body.style.WebkitUserSelect = "none";
      document.body.style.UserSelect = "none";

      window.oncontextmenu = function () {
        return false;
      };
    };
  } catch (err) {
    parent.writeToLog("Viewer init failed - " + err.message);
  }
}());

window.onPresentationLoad = function (presFrame) {
  writeToLog("Presentation ID=" + presFrame + " is loaded.");

  // reportPresentationLoadEvent(presFrame);
};

window.getParentData = function (id, parentId) {
  try {
    if (parent) {
      return parent.getEmbedData(id, parentId);
    }
  } catch (err) {
    writeToLog("getParentData - " + id + " - " + err.message);
  }
};

window.getEmbedData = function (id, parentId) {
  return RiseVision.Viewer.Data.ViewerDataInfo.getEmbedItemData(id, parentId);
};

window.embedReady = function () {
  try {
    let id = window.name;
    if (window.name.indexOf("if_") == 0) {
      id = id.substr(3, id.length);
    }

    if (parent) {
      return parent.itemReady(id, true, true, true, true, true);
    }
  } catch (err) {
    writeToLog("embedReady - " + id + " - " + err.message);
  }
};

window.embedDone = function () {
  try {
    let id = window.name;
    if (window.name.indexOf("if_") == 0) {
      id = id.substr(3, id.length);
    }

    if (parent) {
      return parent.itemDone(id);
    }
  } catch (err) {
    writeToLog("embedDone - " + id + " - " + err.message);
  }
};

window.itemReady = function (presFrame, id, canPlay, canStop, canPause, canReportReady, canReportDone) {
  writeToLog("Item ID=" + id + " is ready.");

  reportReadyEvent(presFrame, id, canPlay, canStop, canPause, canReportReady, canReportDone);
};

window.itemError = function (presFrame, id, reason) {
  writeToLog("Item ID=" + id + " threw an error. Reason - " + reason);

  reportErrorEvent(presFrame, id, reason);
};

window.itemDone = function (presFrame, id) {
  writeToLog("Item ID=" + id + " is finished playing.");

  reportDoneEvent(presFrame, id);
};

window.playCmd = function (presFrame, id) {
  writeToLog("Item ID=" + id + " play.");

  document.getElementById(presFrame).contentWindow.playCmd(id);
};

window.pauseCmd = function (presFrame, id, hide) {
  writeToLog("Item ID=" + id + " pause.");

  document.getElementById(presFrame).contentWindow.pauseCmd(id);
};

window.stopCmd = function (presFrame, id) {
  writeToLog("Item ID=" + id + " stop.");

  document.getElementById(presFrame).contentWindow.stopCmd(id);
};

window.getPlaceholderIFrameIds = function (presFrame, id) {
  return requestPlaceholderIFrameIds(presFrame, id);
};

window.updateVideo = function (presFrame, html, containerName, htmlName, transition) {
  document.getElementById(presFrame).contentWindow.updateVideo(html, containerName, htmlName, transition);
};

window.configureVideo = function (presFrame, htmlName, newHtmlName, volumeParam, autoHideParam, carryOnParam) {
  document.getElementById(presFrame).contentWindow.configureVideo(htmlName, newHtmlName, volumeParam, autoHideParam, carryOnParam);
};

window.showFrameElement = function (presFrame, elementName, show) {
  // if (document.getElementById(presFrame).contentWindow.setVisible) {
  document.getElementById(presFrame).contentWindow.setVisible(elementName, show);
	// }
};

window.writeToLog = function (logEntry) {
  const ts = new Date();
  const txt = ts.toString() + " - " + logEntry;
  console.log( txt );
};

window.createContainer = function (containerName) {
  try {
    let myFrame = getElement(containerName);
    if (myFrame == null) {
      myFrame = createNewElement(containerName, "div", "100%", "100%", 0, 0);
      myFrame.style.overflow = "hidden";

      document.getElementById("mainDiv").appendChild(myFrame);
    }
  } catch (err) {
    writeToLog("createContainer - " + frameName + " - " + err.message);
  }
};

window.createScheduleContainer = function (scheduleName, containerName) {
  try {
    let myFrame = getElement(scheduleName);
    if (myFrame == null) {
      myFrame = createNewElement(scheduleName, "div", "100%", "100%", 0, 0);
      myFrame.style.overflow = "hidden";

      document.getElementById(containerName).appendChild(myFrame);
    }
  } catch (err) {
    writeToLog("createScheduleContainer - " + scheduleName + " - " + err.message);
  }
};

window.createPresentation = function (frameName, containerName, top, left, html, presentationWidth, presentationHeight, hidePointer, enableScroll) {
  try {
    let myFrame = getElement(frameName);
    if (myFrame == null) {
      const myDiv = createNewElement(frameName, "div", "100vw", "100vh", 0, 0);
      myFrame = createNewElement("iFrame_" + frameName, "iFrame", presentationWidth, presentationHeight, 0, 0);

      if (myDiv != null) {
        if (enableScroll) {
          myDiv.style.overflow = "auto";
        } else {
          myDiv.style.overflow = "hidden";
        }
        myDiv.style.visibility = "hidden";
        myDiv.style.opacity = 0;

        if (myFrame != null) {
          myFrame.setAttribute("allowTransparency", true);
          myFrame.setAttribute("allow", "fullscreen");
          myFrame.setAttribute("allowfullscreen", true);
          myFrame.setAttribute("frameBorder", "0");
          myFrame.setAttribute("scrolling", "no");

          myFrame.style.position = "absolute";

          myFrame.dataset.riseWidth = presentationWidth;
          myFrame.dataset.riseHeight = presentationHeight;
          myFrame.dataset.riseScrollx = 0;
          myFrame.dataset.riseScrolly = 0;

          if (!window.isSharedSchedule && !window.isRiseEmbed && myFrame.classList) {
            myFrame.classList.add("legacy-frame-non-shared-schedule");
          }

          document.getElementById(containerName).appendChild(myDiv);
          document.getElementById(frameName).appendChild(myFrame);

          html = stripMetaRefresh(html);
          populateIframe("iFrame_" + frameName, html);
        }
      }

      if (hidePointer) {
        const pointerDiv = createNewElement(frameName + "_pointer", "div", "100%", "100%", 0, 0);

        if (pointerDiv != null) {
          pointerDiv.style.cursor = "url('images/almost_transparent.png'), none";
          pointerDiv.style.zIndex = "1000";
          document.getElementById(frameName).appendChild(pointerDiv);
        }
      }
    }
  } catch (err) {
    writeToLog("createPresentation - " + frameName + " - " + err.message);
  }
};

window.stripMetaRefresh = function (html) {
  html = html.replace(/http-equiv=.refresh./gi, "http-equiv=\"strippedrefresh\"");
  return html;
};

window.populateIframe = function (frameName, html) {
  try {
    let myFrame = getElement(frameName);
    myFrame = (myFrame.contentWindow) ? myFrame.contentWindow : (myFrame.contentDocument.document) ? myFrame.contentDocument.document : myFrame.contentDocument;
    myFrame.document.open();
    myFrame.document.write(html);
    myFrame.document.close();
  } catch (err) {
    writeToLog("populateIframe - " + frameName + " - " + err.message);
  }
};

window.getContentWindow = function (myContainer) {
  try {
    const myContainerFrame = (myContainer.contentWindow) ? myContainer.contentWindow : (myContainer.contentDocument.document) ? myContainer.contentDocument.document : myContainer.contentDocument;

    return myContainerFrame;
  } catch (err) {
    return null;
  }
};

window.setIsRiseEmbed = function (isRiseEmbed) {
  window.isRiseEmbed = isRiseEmbed;
};

window.setIsIgnoredRotationDevice = function (isIgnoredRotationDevice) {
  window.isIgnoredRotationDevice = isIgnoredRotationDevice;
};

window.setIsForcedRotationDevice = function (isForcedRotationDevice) {
  window.isForcedRotationDevice = isForcedRotationDevice;
};

window.setIsSharedSchedule = function (isSharedSchedule) {
  const body = document.body;

  window.isSharedSchedule = isSharedSchedule;

  if (body && body.classList && !window.isRiseEmbed) {
    body.classList.remove("body-non-shared-schedule");

    if (!isSharedSchedule) {
      body.classList.add("body-non-shared-schedule");
    }
  }
};

window.updateViewportOrientation = function () {
  const isPortrait = window.riseOrientation === 90 || window.riseOrientation === 270;
  const isFlipped = window.riseOrientation === 180 || window.riseOrientation === 270;

  const body = document.body;
  const wrapper = document.getElementById("rotation-wrapper");
  const orientationClass = isPortrait ? "portrait" : "landscape";
  const flippedClass = isFlipped ? "flipped" : "normal";

  if (!body || !body.classList) {
    console.error('Missing body.classList');
    return;
  }

  if (!wrapper || !wrapper.classList) {
    console.error('Missing rotation wrapper or wrapper.classList');
    return;
  }

  body.classList.remove(`body-landscape`, `body-portrait`);
  wrapper.classList.remove(...wrapper.classList);

  if (window.isIgnoredRotationDevice) {
    return;
  }

  body.classList.add(`body-${orientationClass}`);
  wrapper.classList.add(`wrapper-${orientationClass}-${flippedClass}`);

  window.dispatchEvent(new Event('resize'));
}

window.isViewportPortrait = function () {
  return window.innerWidth < window.innerHeight;
}

window.isDisplayPortrait = function () {
  return window.riseOrientation === 90 || window.riseOrientation === 270;
}

window.setRiseOrientation = function (orientation) {
  if (window.isRiseEmbed || window.isIgnoredRotationDevice) {
    return;
  }

  if (window.riseOrientation !== orientation) {
    window.riseOrientation = orientation === 0 ? 0 : (orientation || window.riseOrientation);

    if (window.isViewportPortrait() && window.isDisplayPortrait() && !window.isForcedRotationDevice) {
      window.riseOrientation = 0;
    }

    window.updateViewportOrientation();
  }
};

window.adjustContainersSizes = function (iframe, container) {
  const pxToNum = px => parseInt(typeof px === "string" ? px.replace("px", "") : px) || 0;

  if (!iframe) {
    console.error("Missing iframe");
    return;
  }

  if (!container) {
    console.error("Missing container");
    return;
  }

  const width = pxToNum(iframe.dataset.riseWidth);
  const height = pxToNum(iframe.dataset.riseHeight);
  const scrollx = pxToNum(iframe.dataset.riseScrollx);
  const scrolly = pxToNum(iframe.dataset.riseScrolly);

  const isContentPortrait = width < height;
  const contentVpMatch = !window.isDisplayPortrait() && window.isViewportPortrait() === isContentPortrait;
  const displayVpMatch = window.isViewportPortrait() === window.isDisplayPortrait();

  const isRiseTemplate = iframe.dataset && iframe.dataset.isRiseTemplate === "true";
  const preserveXY = !isRiseTemplate || displayVpMatch || contentVpMatch || window.isIgnoredRotationDevice;

  const swapAxes = window.isDisplayPortrait() && !window.isIgnoredRotationDevice;
  const widthVU = swapAxes ? "100vh" : "100vw";
  const heightVU = swapAxes ? "100vw" : "100vh";

  const widthVP = preserveXY ? width + scrollx : height + scrolly;
  const heightVP = preserveXY ? height + scrolly : width + scrollx;
  const scrollxVP = preserveXY ? scrollx : scrolly;
  const scrollyVP = preserveXY ? scrolly : scrollx;

  container.style.width = widthVU;
  container.style.height = heightVU;

  iframe.style.width = widthVP + "px";
  iframe.style.height = heightVP + "px";
  iframe.style.marginLeft = scrollxVP + "px";
  iframe.style.marginTop = scrollyVP + "px";
}

window.createURL = function (frameName, containerName, width, height, top, left, scrollx, scrolly, url, isRiseTemplate) {
  try {
    let myFrame = getElement(frameName);
    if (myFrame == null) {
      const myDiv = createNewElement(frameName, "div", width, height, 0, 0);
      myFrame = createNewElement("iFrame_" + frameName, "iFrame", width, height, 0, 0);

      if (myDiv != null) {
        myDiv.style.overflow = "hidden";

        if (myFrame != null) {
          myFrame.style.position = "absolute";

          myFrame.dataset.isRiseTemplate = !!isRiseTemplate;
          myFrame.dataset.riseWidth = width;
          myFrame.dataset.riseHeight = height;
          myFrame.dataset.riseScrollx = scrollx;
          myFrame.dataset.riseScrolly = scrolly;

          // Add https:// if no protocol parameter exists
          if (url.indexOf("://") == -1) {
            url = "https://" + url;
          }

          myFrame.setAttribute("src", url);
          myFrame.setAttribute("allowTransparency", true);
          myFrame.setAttribute("allow", "fullscreen");
          myFrame.setAttribute("allowfullscreen", true);
          myFrame.setAttribute("frameBorder", "0");
          myFrame.setAttribute("scrolling", "no");

          myDiv.style.visibility = "hidden";
          myDiv.style.opacity = 0;

          window.adjustContainersSizes(myFrame, myDiv);

          document.getElementById(containerName).appendChild(myDiv);
          document.getElementById(frameName).appendChild(myFrame);
        }
      }
    }
  } catch (err) {
    writeToLog("createURL - " + frameName + " - " + err.message);
  }
};

window.createNewElement = function (frameName, type, width, height, top, left) {
  try {
    const myFrame = document.createElement(type);

    if (myFrame != null) {
      myFrame.setAttribute("id", frameName);

      myFrame.style.position = "absolute";

      myFrame.style.left = setUnit(left);
      myFrame.style.top = setUnit(top);
      myFrame.style.width = setUnit(width);
      myFrame.style.height = setUnit(height);
    }
    return myFrame;
  } catch (err) {
    writeToLog("createNewElement - " + frameName + " - " + err.message);
  }
};

window.setUnit = function (value) {
  if (isNumber(value)) {
    return value + "px";
  }
  return value;
};

window.isNumber = function (n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
};

window.getElement = function (elementName) {
  try {
    const myElement = document.getElementById(elementName);
    return myElement;
  } catch (err) {
    return null;
  }
};

window.showElement = function (elementName) {
  showElementAttempt(elementName, 0);
};

window.showElementAttempt = function (elementName, attempt) {
  try {
    const myElement = document.getElementById(elementName);

    if (myElement && myElement.style.visibility != "visible") {
      myElement.style.visibility = "visible";
      myElement.style.opacity = 1;
    } else if (!myElement && attempt < 3) {
      setTimeout(showElementAttempt.bind(null, elementName, attempt + 1), 500);
    }
  } catch (err) {
    writeToLog("showElement - " + elementName + " - " + err.message);
  }
};

window.hideElement = function (elementName) {
  try {
    const myElement = document.getElementById(elementName);

    myElement.style.visibility = "hidden";
    myElement.style.opacity = 0;
  } catch (err) {
    writeToLog("hideElement - " + elementName + " - " + err.message);
  }
};

window.destroyElement = function (elementName, containerName) {
  try {
    const myElement = document.getElementById(elementName);

    document.getElementById(containerName).removeChild(myElement);
  } catch (err) {
    writeToLog("destroyElement - " + elementName + " - " + err.message);
  }
};

window.setBackground = function (elementName, color) {
  try {
    const element = document.getElementById(elementName);
    if (element) {
      element.style.backgroundColor = color;
    }
  } catch (err) {
    writeToLog("setBackground - " + elementName + " - " + err.message);
  }
};
