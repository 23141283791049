class PopupPanelLegacy {
  constructor({ autoHide, modalBackdrop, containerId }) {
    this.$elem = window.$(`#${containerId}`);
    this.$content = this.$elem.find(".popup-content");
    this.closeButton = this.$elem.find(".close");
    this.confirmButton = this.$elem.find(".modal-footer .btn");
    if (modalBackdrop !== false) {
      this.$backdrop = window.$(".modal-backdrop");
    }

    this._documentClickHandler = () => {
      if ((autoHide && event.target === this.$elem[0]) ||
        (this.closeButton[0] && this.closeButton[0].contains(event.target)) ||
        (this.confirmButton[0] && this.confirmButton[0].contains(event.target))) {
        this.hide();
      }
    };
  }

  show(message) {
    if (message) {
      this.$content.html(message);
    }

    this.$elem.show();
    this.$backdrop && this.$backdrop.show();

    $(document).on('click', this._documentClickHandler);
    $(document).on('touchstart', this._documentClickHandler);
  }

  size(width, height) {
    this.$content.css("width", `${width}px`);
    this.$content.css("height", `${height}px`);
  }

  hide() {
    this.$elem.hide();
    this.$backdrop && this.$backdrop.hide();

    $(document).off('click', this._documentClickHandler);
    $(document).off('touchstart', this._documentClickHandler);
  }
}

module.exports = PopupPanelLegacy;
