const ConfigurationFactory = require("../ConfigurationFactory");
const Logger = require("../Logger");
const ViewerDataController = require("./ViewerDataController");

const service = {};

const _handleStorageEvent = () => {
  ViewerDataController.setDuplicateInstance();
};

service.init = () => {
  const displayId = ConfigurationFactory.getDisplayId();
  let duplicateInstance = false;
  const timestamp = Date.now();
  const instanceObject = {
    timestamp,
    displayId,
  };

  const handlerFunction = (event) => {
    const type = event.key;
    let newInstanceObject = 0;
    if (type === "instance") {
      try {
        newInstanceObject = window.localStorage.instance || 0;

        newInstanceObject = JSON.parse(newInstanceObject);
      } catch (err) {
        Logger.logException("ViewerInstanceController.init.handlerFunction", err);
      }

      // check if instance is using the same Display Id
      if (newInstanceObject && newInstanceObject.displayId === instanceObject.displayId) {
        // block current instance
        if (!duplicateInstance) {
          _handleStorageEvent();

          duplicateInstance = true;

          Logger.log("Instance Controller - duplicate found, shutting down");
        }

        if (newInstanceObject.timestamp > timestamp) {
          window.localStorage.setItem("instance", JSON.stringify(instanceObject));

          Logger.log("Instance Controller - sent timestamp");
        } else {
          _removeEvent();
        }
      }
    }
  };

  let _removeEvent = () => {
    window.removeEventListener("storage", handlerFunction, false);

    Logger.log("Instance Controller - removed event");
  };

  window.addEventListener("storage", handlerFunction, false);

  try {
    window.localStorage.setItem("instance", JSON.stringify(instanceObject));
    Logger.log("Instance Controller - initial timestamp");
  } catch (err) {
    Logger.logException("ViewerInstanceController.init._removeEvent", err);
  }
};

module.exports = service;
