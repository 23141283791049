/* eslint-disable class-methods-use-this */
const ConfigurationFactory = require("../ConfigurationFactory");
const Logger = require("../Logger");

class ViewerZoomController {
  constructor() {
    this.$elem = document.getElementById("zoom-selector");
    this._zoomLevel = 1;
    this._zoomAxis = null;
    this._containerIds = [];
    this._containerSizes = [];
    this._containerZoomLevels = [];
    this._containerZoomAxes = [];

    this._populatePieces();

    this._initHandlers();
  }

  _populatePieces() {
    const $c = this.$elem;
    this.$zoomInButton = $c.querySelector("#zoom-in");
    this.$zoomOutButton = $c.querySelector("#zoom-out");
    this.$zoomFitButton = $c.querySelector("#zoom-fit");
    this.$zoomNumberLabel = $c.querySelector("#zoom-number");
  }

  _initHandlers() {
    const _this = this;

    this.$zoomInButton.addEventListener("click", () => _this._zoomIn());
    this.$zoomOutButton.addEventListener("click", () => _this._zoomOut());
    this.$zoomFitButton.addEventListener("click", () => _this.zoomFit());
  }

  _canZoomIn() {
    return this._zoomLevel <= 1.99;
  }

  _canZoomOut() {
    return this._zoomLevel >= 0.21;
  }

  _zoomIn() {
    if (this._canZoomIn()) {
      this._zoomLevel = Math.floor(this._zoomLevel * 10 + 1) / 10;
      this._zoomAxis = null;

      this.applyZoom();
    }
  }

  _zoomOut() {
    if (this._canZoomOut()) {
      this._zoomLevel = Math.ceil(this._zoomLevel * 10 - 1) / 10;
      this._zoomAxis = null;

      this.applyZoom();
    }
  }

  _shouldShowPoweredByRiseBar() {
    const platform = ConfigurationFactory.getEnv(true);
    return ConfigurationFactory.isSharedschedule() && !ConfigurationFactory.isEmbed() && platform !== "embed" && platform.indexOf("apps") !== 0;
  }

  requiresZoomFit() {
    if (ConfigurationFactory.isScaleContentToFitScreen()) {
      return !ConfigurationFactory.isPreview();
    }

    return ConfigurationFactory.isSharedschedule() || ConfigurationFactory.isAndroidPlayer();
  }

  zoomFit() {
    let artboardWidth = 0, artboardHeight = 0, containerFitWidth = 0, containerFitHeight = 0, containerFitRatio = 1, containerZoomAxis = "x";

    if (!this._containerSizes.length) {
      return;
    }

    this._containerZoomLevels = [];
    this._containerZoomAxes = [];

    const workspaceWidth = document.body.clientWidth;
    const workspaceHeight = document.body.clientHeight - (this._shouldShowPoweredByRiseBar() ? 25 : 0);

    for (let i = 0; i < this._containerSizes.length; i++) {
      let isRiseTemplate = false;

      if (this._containerIds.length > i) {
        isRiseTemplate = document.getElementById(`iFrame_${this._containerIds[i]}`).dataset.isRiseTemplate === "true";
      } else {
        Logger.logDebug("No container id found for index", i);
      }

      const adjustPortrait = isRiseTemplate ? window.isViewportPortrait() : window.isDisplayPortrait();
      const swapAxes = adjustPortrait && !window.isIgnoredRotationDevice;
      const xAxis = !swapAxes ? 0 : 1;
      const yAxis = !swapAxes ? 1 : 0;

      artboardWidth = Math.max(artboardWidth, this._containerSizes[i][xAxis]);
      artboardHeight = Math.max(artboardHeight, this._containerSizes[i][yAxis]);

      containerFitWidth = workspaceWidth / this._containerSizes[i][xAxis];
      containerFitHeight = workspaceHeight / this._containerSizes[i][yAxis];
      containerFitRatio = Math.min(containerFitWidth, containerFitHeight);
      containerFitRatio = Math.min(5, containerFitRatio);
      containerFitRatio = Math.max(0.05, containerFitRatio);
      containerZoomAxis = (containerFitWidth > containerFitHeight) ? "x" : "y";

      this._containerZoomLevels.push(containerFitRatio);
      this._containerZoomAxes.push(containerZoomAxis);
    }

    const fitWidth = workspaceWidth / artboardWidth;
    const fitHeight = workspaceHeight / artboardHeight;

    let fitRatio = Math.min(fitWidth, fitHeight);
    fitRatio = Math.min(5, fitRatio);
    fitRatio = Math.max(0.05, fitRatio);

    this._zoomLevel = fitRatio;
    this._zoomAxis = (fitWidth > fitHeight) ? "x" : "y";

    this.applyZoom();
  }

  applyZoom() {
    if (!ConfigurationFactory.isDisplay() && this._containerIds.length) {
      let topOnZero = false;
      let maxTop = 0;
      let zoomLevels = "";

      for (let i = 0; i < this._containerIds.length; i++) {
        const $c = document.getElementById(`iFrame_${this._containerIds[i]}`);
        const $d = document.getElementById(this._containerIds[i]);

        if (!$c || $c.offsetTop === undefined) { continue; }

        // Adjust window sizes based on the content orientation
        window.adjustContainersSizes($c, $d);

        // Translate the content to the center of the container
        let translate = "", translateX = "0px", translateY = "0px";

        if (this.requiresZoomFit()) {
          if (!this._containerZoomAxes[i] || this._containerZoomAxes[i] === "x") {
            if (!window.isDisplayPortrait()) {
              $c.style.left = "50%";
              translateX = "-50%";
            } else {
              $c.style.top = "50%";
              translateY = "-50%";
            }
          } else {
            if (!window.isDisplayPortrait()) {
              $c.style.left = "0px";
            } else {
              $c.style.top = "0px";
            }
          }

          if (!this._containerZoomAxes[i] || this._containerZoomAxes[i] === "y") {
            if (this._shouldShowPoweredByRiseBar()) {
              if (!window.isDisplayPortrait()) {
                $c.style.top = "calc(50% - 12px)";
              } else {
                $c.style.left = "calc(50% - 12px)";
              }
            } else {
              if (!window.isDisplayPortrait()) {
                $c.style.top = "50%";
              } else {
                $c.style.left = "50%";
              }
            }

            if (!window.isDisplayPortrait()) {
              translateY = "-50%";
            } else {
              translateX = "-50%";
            }
          } else {
            if (!window.isDisplayPortrait()) {
              $c.style.top = "0px";
            } else {
              $c.style.left = "0px";
            }

            topOnZero = true;
          }

          translate = ` translate(${translateX}, ${translateY})`;
        }

        if (this.requiresZoomFit()) {
          $c.style.transform = `scale(${this._containerZoomLevels[i]})${translate}`;
        } else {
          $c.style.transform = `scale(${this._zoomLevel})${translate}`;
        }

        zoomLevels += `${Math.round(this._containerZoomLevels[i] * 100)}%` + " ";

        $c.style.transformOrigin = "0% 0%";
        $c.style.transition = this._shouldShowPoweredByRiseBar() ? "all 0s" : "all .4s";

        maxTop = Math.max(maxTop, $c.offsetTop + $c.offsetHeight * this._zoomLevel / 2);
      }

      zoomLevels = zoomLevels.trim();

      if (this._shouldShowPoweredByRiseBar()) {
        const poweredByRiseDiv = window.document.getElementById("powered-by-rise-div");
        poweredByRiseDiv.style.visibility = "visible";
        if (topOnZero) {
          poweredByRiseDiv.style.top = "initial";
          poweredByRiseDiv.style.bottom = "0";
        } else {
          poweredByRiseDiv.style.top = maxTop + "px";
          poweredByRiseDiv.style.bottom = "initial";
        }
      }
      this.$zoomNumberLabel.textContent = this.requiresZoomFit() ? zoomLevels : `${Math.round(this._zoomLevel * 100)}%`;
    }
  }

}

let instance;

// singleton
function _getInstance() {
  if (!instance) {
    instance = new ViewerZoomController();
  }

  return instance;
}

ViewerZoomController.init = (containerId, width, height) => {
  _getInstance()._zoomLevel = 1;
  _getInstance()._containerIds.push(containerId);
  _getInstance()._containerSizes.push([width, height]);

  if (_getInstance().requiresZoomFit()) {
    _getInstance().zoomFit();
  } else {
    _getInstance().applyZoom();
  }
};

ViewerZoomController.zoomFit = () => {
  _getInstance().zoomFit();
};

ViewerZoomController.resetContainers = () => {
  _getInstance()._containerIds = [];
  _getInstance()._containerSizes = [];
};

module.exports = ViewerZoomController;
