class PopupPanel {
  constructor({ autoHide, modalBackdrop, containerId }) {
    this.$elem = document.getElementById(containerId);
    this.$content = this.$elem.querySelector(".popup-content");
    this.closeButton = this.$elem.querySelector(".close");
    this.confirmButton = this.$elem.querySelector(".modal-footer .btn");
    if (modalBackdrop !== false) {
      this.$backdrop = document.querySelector(".modal-backdrop");
    }

    this._documentClickHandler = (event) => {
      if ((autoHide && event.target === this.$elem[0]) ||
        (this.closeButton[0] && this.closeButton[0].contains(event.target)) ||
        (this.confirmButton[0] && this.confirmButton[0].contains(event.target))) {
        this.hide();
      }
    };
  }

  show(message) {
    if (message) {
      this.$content.innerHTML = message;
    }

    this.$elem.style.display = 'block';
    if (this.$backdrop) {
      this.$backdrop.style.display = 'block';
    }

    document.addEventListener('click', this._documentClickHandler);
    document.addEventListener('touchstart', this._documentClickHandler);
  }

  size(width, height) {
    this.$content.style.width = `${width}px`;
    this.$content.style.height = `${height}px`;
  }

  hide() {
    this.$elem.style.display = 'none';
    if (this.$backdrop) {
      this.$backdrop.style.display = 'none';
    }

    document.removeEventListener('click', this._documentClickHandler);
    document.removeEventListener('touchstart', this._documentClickHandler);
  }
}

module.exports = PopupPanel;
