const Global = require("./Config/Global");
const ConfigurationFactory = require("./ConfigurationFactory");
const ClaimIdBasedActivation = require("./Data/ClaimIdBasedActivation");
const RemoteRequests = require("./Data/RemoteRequests");
const AnalyticsFactory = require("./AnalyticsFactory");
const DuplicateIdHandler = require("./DuplicateIdHandler")
const MachineId = require("./Machine-id");
const Logger = require("./Logger");
const ContentSentinelDispatcher = require("content-sentinel");
const Utils = require("./Utils");
const ChromeOSPwaExtensionMessaging = require("./Player/ChromeOSPwaExtensionMessaging");
const PlayerController = require("./Player/RisePlayerController");
const DisplayControlService = require("./Player/DisplayControlService");
const PlayerIntegration = require("./Player/PlayerIntegration");
const BrowserCompatibilityService = require("./BrowserCompatibilityService");
const BrowserStorageQuotaService = require("./BrowserStorageQuotaService");
const BrowserNetworkConnectionService = require("./BrowserNetworkConnectionService");
const CorsIssueWorkaround = require("./Issues/CorsIssueWorkaround");
const FirestickStillWatchingIssueWorkaround = require("./Issues/FirestickStillWatchingIssueWorkaround");
const QuotaExceededIssueWorkaround = require("./Issues/QuotaExceededIssueWorkaround");
const FloatingNotificationsController = require("./UI/FloatingNotificationsController");
const LowPerformanceDetection = require("./Performance/LowPerformanceDetection");
const ContentSentinelInitializationErrorUIController = require("./UI/ContentSentinelInitializationErrorUIController.js");
const WebPlayerRegistrationUIController = require("./UI/WebPlayerRegistrationUIController.js");
const WebPlayerActivationUIController = require("./UI/WebPlayerActivationUIController");

const service = {};

const PLAYER_PARAM = "player=";
const E2E_PARAM = "e2e-tests=";
const ONE_MINUTE = 60 * 1000;
const QUOTA_EXCEEDED_ERROR_CODE = "E000000092";

let contentController, newContentController;
let onDataReady;

let isShowingBlack = undefined, isShowingProgressBar = true;

let priorityContentTimeout;

const _preparePlayer = () => {
  if (!ConfigurationFactory.isDisplay()) {
    if (ConfigurationFactory.isElectronPlayer()) {
      PlayerController.startWatchdogPing();
    }

    return;
  }

  PlayerController.setIsActive(Utils.getFromQueryString(PLAYER_PARAM));
};

const _registerServiceWorker = () => {
  RiseVision.Viewer.ServiceWorker.register(() => {
    _load();
  });
};

const _load = () => {
  ConfigurationFactory.displayLogging();

  // check if no Display ID
  if (PlayerController.getIsActive() && ConfigurationFactory.isDisplay() && !ConfigurationFactory.isEmbed() &&
    ConfigurationFactory.DEMO_ID === ConfigurationFactory.getId()) {
    if (ConfigurationFactory.getClaimId()) {
      RiseVision.Viewer.UI.DisplayRegister.EnterClaimIdWidget.show(false);
    } else {
      RiseVision.Viewer.UI.DisplayRegister.DisplayRegisterWidget.show("DISPLAY_ID_NULL");
    }
  } else {
    RiseVision.Viewer.Messaging.init(() => {
      RiseVision.Viewer.Data.GCSController.init();
      RiseVision.Viewer.LocalMessaging.init(() => {
        service.loadPresentation();
      });
    });
  }
};

function _adjustStylesThatCauseAlignmentIssues() {
  if (ConfigurationFactory.isAndroidPlayer()) {
    const poweredByRiseDiv = window.document.getElementById("powered-by-rise-div");

    poweredByRiseDiv.style.padding = "0";
  }
}

service.checkChromeOSPwaPlayerExtension = () => {
  ConfigurationFactory.updateParameters();

  if( !ConfigurationFactory.isWebPlayer() || ConfigurationFactory.isEmbed() ) { 
    return Promise.resolve(false);
  }

  const key = ConfigurationFactory.CHROME_OS_PWA_PLAYER_CONFIGURATION;

  return ChromeOSPwaExtensionMessaging.initializeAndGetConfiguration()
  .then( configuration => {
    console.log( "Chrome OS PWA Player extension found, and has been initialized" );

    window.sessionStorage.setItem( key, JSON.stringify(configuration) );    

    return true;
  }).catch( error => {
    console.log( "Chrome OS PWA Player extension not found or failed.", error && error.message );

    window.sessionStorage.removeItem( key );
    return false;
  });
}

const _validateDisplayId = displayId => {
  if (!displayId) {
    return Promise.resolve();
  }

  return RemoteRequests.validateDisplayId( displayId )
  .catch(error=>{
    if (WebPlayerRegistrationUIController.isIdNotValidError(error)) {
      FloatingNotificationsController.addNotification(`Display id not valid: '${displayId}' - message: '${ error && error.message }'`, ONE_MINUTE);
      FloatingNotificationsController.showNotifications();

      try {
        localStorage.removeItem(ConfigurationFactory.WEBPLAYER_DISPLAY_ID_KEY);
        Utils.removeParamFromURL(ConfigurationFactory.getIDParamName());
      } catch(e) {}
    }
  });
};


service.configureDisplayId = () => {
  ConfigurationFactory.updateParameters();

  if (!localStorage.getItem(ConfigurationFactory.WEBPLAYER_DISPLAY_ID_KEY)) {
    PlayerIntegration.loadDisplayIdFromPlayerIfAvailable();
  }

  if (!ConfigurationFactory.isWebPlayer()) {return Promise.resolve();}
  if (ConfigurationFactory.isEmbed()) {return Promise.resolve();}

  const activationCode = ConfigurationFactory.getActivationCode();
  const displayId = ConfigurationFactory.getDisplayId();

  if (!displayId || activationCode) {
    const claimId = ConfigurationFactory.getClaimId();

    if (claimId) {
      return ClaimIdBasedActivation.generateDisplayIdFromClaimId(claimId);
    }
  }

  if (activationCode) {return Promise.resolve();}

  return _validateDisplayId(displayId);
};

service.handleContentSentinelLogMessage = logEntry => {
  RiseVision.Viewer.Logger.logTemplateEvent( logEntry );

  if( logEntry && logEntry.eventErrorCode === QUOTA_EXCEEDED_ERROR_CODE ) {
    QuotaExceededIssueWorkaround.handleQuotaExceeded();
  }
};

// This is called by Content Sentinel's dispatcher
service.reloadPageIfCorsIssueBrowserMatches = message =>
  CorsIssueWorkaround.reloadPageIfCorsIssueBrowserMatches(message);

function startContentSentinel() {
  const endpointId = ConfigurationFactory.getDisplayId() || Utils.getUniqueId();

  const csPromiseFn = () => ConfigurationFactory.useContentSentinel() ? ContentSentinelDispatcher.init({
    displayId: RiseVision.Viewer.ConfigurationFactory.getDisplayId(),
    machineId: MachineId.id(),
    heartbeatInterval: RiseVision.Viewer.Logger.heartbeatInterval(),
    heartbeatLogFn: RiseVision.Viewer.Logger.recordUptimeHeartbeat,
    logFn: service.handleContentSentinelLogMessage,
    endpointType: RiseVision.Viewer.ConfigurationFactory.endpointType(),
    endpointId,
    scheduleId: ConfigurationFactory.getScheduleId(),
    playerMessageFn: PlayerIntegration.playerMessageFn,
    duplicateIdHandlerFn: DuplicateIdHandler.handleDuplicateId,
  }) : Promise.resolve({ success: true });

  const timeoutPromise = ConfigurationFactory.isDisplay() ? new Promise(res=>setTimeout(res, Global.DELAY_FOR_INJECTED_SCRIPTS)) : Promise.resolve();

  return timeoutPromise
    .then(csPromiseFn);
}

function startViewer() {
  const endpointId = ConfigurationFactory.getDisplayId() || Utils.getUniqueId();

  setInterval(Logger.recordUptimeHeartbeat, Logger.heartbeatInterval());

  const quotaFn = BrowserStorageQuotaService.checkStorageQuota;
  const networkFn = BrowserNetworkConnectionService.checkNetworkConnections;

  return quotaFn()
  .then(networkFn)
  .then(_registerServiceWorker)
  .then(() => CorsIssueWorkaround.init(endpointId))
  .catch((error = {}) =>{
    Logger.viewerError( "E999999999", "Unexpected error during Viewer init", JSON.stringify({ error: error.stack }) );
  });
}

service.waitUntilContentJsonExists = () => {
  if (ConfigurationFactory.isWebPlayer() && !ConfigurationFactory.isEmbed() && !Utils.getFromQueryString(E2E_PARAM)) {
    return WebPlayerActivationUIController.waitUntilContentJsonExists(service.init);
  } else {
    return Promise.resolve();
  }
}

service.init = () => {
  ConfigurationFactory.updateParameters();

  PlayerIntegration.bindPlayerUpdateButton();

  _adjustStylesThatCauseAlignmentIssues();

  if (ConfigurationFactory.needsWebPlayerDisplayId()) {
    if (ConfigurationFactory.getDisplayIdAsCancelled() || Utils.getFromQueryString(E2E_PARAM)) {
      let showActivationFn = WebPlayerActivationUIController.showWebPlayerActivationUI;

      return WebPlayerRegistrationUIController.showWebPlayerRegistrationUI(service.init, showActivationFn);
    } else {
      return WebPlayerActivationUIController.showWebPlayerActivationUI(service.init);
    }
  }

  const endpointId = ConfigurationFactory.getDisplayId() || Utils.getUniqueId();

  Logger.initEndpointLogger({
    endpointId,
    endpointType: ConfigurationFactory.endpointType(),
    endpointUrl: window.location.href,
    scheduleId: ConfigurationFactory.getScheduleId(),
    embedReferralUrl: ConfigurationFactory.isEmbedSharedSchedule() ? document.referrer : null,
    viewerVersion: Global.VIEWER_VERSION
  });

  Logger.setEndpointLoggerContentFields({ scheduleId: ConfigurationFactory.getScheduleId() });

  Logger.recordUptimeHeartbeat();

  Logger.reportRemoteDebugToPlayer(PlayerIntegration.getInjectedPlayer());

  PlayerIntegration.configureHeartBeatInterval();

  _preparePlayer();

  if (!BrowserCompatibilityService.checkAllowedBrowser()) { return; }

  return BrowserCompatibilityService.checkBrowserCompatibility()
  .then(compatible=>{
    if (compatible) {
      LowPerformanceDetection.start();
      FirestickStillWatchingIssueWorkaround.init();

      if (Utils.isScreensaver()) {
        RiseVision.Viewer.Player.ScreensaverInteractionController.init();
      }

      return startContentSentinel().then(outcome => {
        if( !outcome.success && ContentSentinelInitializationErrorUIController.shouldShowErrorPage( outcome.failure ) ) {
          ContentSentinelInitializationErrorUIController.show( outcome.failure );
          return;
        }

        return service.waitUntilContentJsonExists()
        .then(startViewer);
      });
    }
  });
};

service.loadPresentation = () => {
  if (ConfigurationFactory.isDisplay()) {
    RiseVision.Viewer.Cache.RiseCacheController.pingCache();
  }

  const id = ConfigurationFactory.getId();

  if (id && ConfigurationFactory.isValidType()) {
    RiseVision.Viewer.Data.ViewerDataController.init(_onDataReady);

    PlayerIntegration.setDisplayId(id);
  }

  FloatingNotificationsController.showNotifications();
};

service.reloadContentController = () => {
  if (newContentController) {
    newContentController.unload();
  }

  let onContentDone = null;
  if (ConfigurationFactory.isEmbed()) {
    onContentDone = _reportDone;
  }

  newContentController = new RiseVision.Viewer.Content.ContentController(_onContentReady, onContentDone);
  newContentController.init();
}

function _onDataReady() {
  service.reloadContentController();

  if (ConfigurationFactory.isPreview() && ConfigurationFactory.getShowUi()) {
    RiseVision.Viewer.UI.ViewerPreviewWidget.show();
  }

  AnalyticsFactory.init();
}

function _onContentReady() {
  const oldContentController = contentController;

  contentController = newContentController;

  // Reset the newContentController parameter
  newContentController = null;

  if (ConfigurationFactory.isEmbed()) {
    _reportReady();
  }
  else {
    _playContent();
  }

  if (oldContentController) {
    oldContentController.unload();
  }

  AnalyticsFactory.trackLoadTime();
}

function _playContent() {
  if (isShowingProgressBar) {
    if (Utils.wakeupSignalHasBeenReceived()) {
      if (!contentController.checkPriorityContent()) {
        if (!priorityContentTimeout) {
          priorityContentTimeout = setTimeout(() => {
            Logger.logDebug('notifyOfRegularContent called after timeout');

            PlayerIntegration.notifyOfRegularContent();
          }, 60 * 1000);
        }

        Logger.logDebug('isShowingProgressBar skip playback; wait for priority content');

        return;
      } else if (priorityContentTimeout) {
        clearTimeout(priorityContentTimeout);
      }

      Logger.logDebug('isShowingProgressBar priority content received; proceed');
    }

    Utils.showElement("progress", false);

    isShowingProgressBar = false;
  }

  if (!contentController.play()) {
    _reportDone();
  }
}

service.showBlackScreen = function (show) {
  if (isShowingBlack === show) {return;}

  const validType = ConfigurationFactory.isSharedschedule() ||
    ConfigurationFactory.isDisplay() ||
    ConfigurationFactory.isWebPlayer()

  if (!validType) {return;}

  isShowingBlack = show;
  Utils.setBackground("mainDiv", show ? "black" : "transparent");

  DisplayControlService.setDisplayControl(show);

  PlayerController.setDisplayCommand(show);
};

service.isShowingProgressBar = function () {
  return isShowingProgressBar;
};

function _reportReady() {
  try {
    window.embedReady();
  } catch (err) {
    Logger.logException("EntryPoint._reportReady", err);
  }
}

function _reportDone() {
  try {
    window.embedDone();
  } catch (err) {
    Logger.logException("EntryPoint._reportDone", err);
  }
}

service.embedPlay = function () {
  _playContent();
};

service.embedPause = function () {
  contentController.pause();
};

service.embedStop = function () {
  service.embedPause();
};

service.destroyContent = function() {
  if (!contentController) {
    return;
  }

  contentController.unload();
  contentController = null;
}

// This functionn is used to reset the service for unit tests
service._reset = function() {
  isShowingProgressBar = true;
  if (priorityContentTimeout) {
    clearTimeout(priorityContentTimeout);
    priorityContentTimeout = null;
  }
};

module.exports = service;
