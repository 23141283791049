const ConfigurationFactory = require("../ConfigurationFactory");
const PlayerIntegration = require("../Player/PlayerIntegration");

let domEl = null;
let progressEl = null;
let initialized = false;

const SECONDS = 1000;
const MINUTES = 60 * SECONDS;
const HOURS = 60 * MINUTES;
const RELOAD_TIMEOUTS = [1 * MINUTES, 2 * MINUTES, 4 * MINUTES];
const DISABLE_RESTART_INTERVAL = 6 * HOURS;
const WEBPLAYER_SERVICE_WORKER_REGISTRATION_RELOAD_COUNT = "webplayer-service-worker-registration-reload-count";
const WEBPLAYER_LAST_SERVICE_WORKER_REGISTRATION_RELOAD = "webplayer-last-service-worker-registration-reload";

var reloadCount = 0;
var lastReloaded = 0;

const readReloadCount = () => {
  const value = localStorage.getItem( WEBPLAYER_SERVICE_WORKER_REGISTRATION_RELOAD_COUNT );

  return value ? Number(value) : 0;
}

const readLastReloaded = () => {
  const value = localStorage.getItem( WEBPLAYER_LAST_SERVICE_WORKER_REGISTRATION_RELOAD );

  return value ? Number(value) : 0;
}

const saveReloadValues = () => {
  localStorage.setItem( WEBPLAYER_SERVICE_WORKER_REGISTRATION_RELOAD_COUNT, reloadCount+1 );
  localStorage.setItem( WEBPLAYER_LAST_SERVICE_WORKER_REGISTRATION_RELOAD, Date.now() );
}

const init = () => {
  if (initialized) {return;}
  initialized = true;

  domEl = document.querySelector("#content-sentinel-initialization-error");
  progressEl = document.querySelector("#progress");

  const savedCount = readReloadCount();
  lastReloaded = readLastReloaded();
  reloadCount = calculateReloadCount( savedCount, lastReloaded );
}

const calculateReloadCount = ( savedCount, lastTimestamp ) => {
  const now = Date.now();

  if( now - lastTimestamp > DISABLE_RESTART_INTERVAL ) {
    return 0;
  }

  return savedCount;
}

const programReloadTimeout = () => {
  const timeout = RELOAD_TIMEOUTS[ reloadCount ];

  setTimeout( () => PlayerIntegration.requestRestart(), timeout );
}

module.exports = {
  calculateReloadCount,
  shouldShowErrorPage( failure ) {
    return ConfigurationFactory.isGiadaRecommendedDevice() || failure === 'service worker registration failed';
  },
  show( failure ) {
    init();

    domEl.style.display = "flex";
    progressEl.style.display="none";

    if( reloadCount < RELOAD_TIMEOUTS.length ) {
      programReloadTimeout();
      saveReloadValues();
    }
  }
};
